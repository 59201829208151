$mdc-theme-primary: #193163;
$mdc-theme-secondary: #f9ba5c;
$mdc-theme-surface: #f7f6f4;
$the-pinkish-color: #ff806d;

$mdc-typography-font-family: 'Fira Sans', 'Trebuchet MS', 'Helvetica Neue', 'Arial', sans-serif;

// MDC Web does not have many breakpoints. Use Bootstrap breakpoints instead.
// Source: https://github.com/material-components/material-components-web/issues/23#issuecomment-346907008
$mdc-layout-grid-breakpoints: (
  largedesktop: 1200px,
  desktop: 992px,
  tablet: 768px,
  largephone: 576px,
  phone: 0px,
);

$mdc-layout-grid-columns: (
  largedesktop: 12,
  desktop: 12,
  tablet: 8,
  largephone: 6,
  phone: 4,
);

$mdc-layout-grid-default-margin: (
  largedesktop: 32px,
  desktop: 24px,
  tablet: 16px,
  largephone: 16px,
  phone: 16px,
);

$mdc-layout-grid-default-gutter: (
  largedesktop: 32px,
  desktop: 24px,
  tablet: 16px,
  largephone: 16px,
  phone: 16px,
);

$mdc-layout-grid-column-width: (
  largedesktop: 72px,
  desktop: 72px,
  tablet: 72px,
  largephone: 72px,
  phone: 72px,
);
