.buttonLink {
  color: inherit;
  text-decoration: none;
}

// Style an element to look like a link
.link {
  cursor: pointer;
  text-decoration: underline;
}
