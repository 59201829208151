@import url('https://fonts.googleapis.com/css?family=Material+Icons');
@import url('https://fonts.googleapis.com/css?family=Fira+Sans');
@import './styles/mdc';

body {
  margin: 0;
  padding: 0;
  font-family: $mdc-typography-font-family;

  // Make iOS fire onclick events on body. Necessary to dismiss MDC menus.
  // See https://github.com/material-components/material-components-web/issues/693#issuecomment-391648995
  @supports (-webkit-overflow-scrolling: touch) {
    cursor: pointer;
  }
}

h1 {
  font-size: 250%;
}

h1,
h2,
h3 {
  font-weight: 300;
  margin: inherit, 10px;
}
